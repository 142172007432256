<template>
    <div class="content-inAnalysis" style="height: 100%;">
        <div class="content-all">
            <div class="analysis-select">
                <div class="call-item mar-left">
                    <span class="span-ts">部门：</span>
                    <div class="select-width">
                        <el-cascader
                            ref="cascaderHandled"
                            :key="cascaderKey"
                            v-model="ccc"
                            :options="getDepartments"
                            size="mini"
                            :props="{expandTrigger: 'hover', checkStrictly: true, label: 'name', value: 'id'}"
                            placeholder="选择部门"
                            clearable
                            @change="getStaff_Val"
                        />
                    </div>
                </div>
                <div class="call-item mar-left">
                    <span class="span-ts">员工：</span>
                    <div class="select-width">
                        <el-select
                            v-model="yg"
                            placeholder="请选择"
                            size="mini"
                            clearable
                            @change="getDepartmentMemberChange"
                        >
                            <el-option
                                v-for="item in getStaff"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="call-item mar-left">
                    <span class="span-ts">通话类型：</span>
                    <div class="select-width">
                        <el-select
                            v-model="lx"
                            placeholder="请选择"
                            size="mini"
                            clearable
                            @change="getCallTypeChange"
                        >
                            <el-option
                                v-for="item in callType"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="call-item mar-left">
                    <span class="span-ts">通话时长：</span>
                    <div class="select-width">
                        <el-select
                            v-model="valued"
                            placeholder="请选择"
                            size="mini"
                            clearable
                            @change="callDurationChange"
                        >
                            <el-option
                                v-for="item in getCallDuration"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="select-date mar-left">
                    <span class="span-ts">时间：</span>
                    <el-date-picker
                        v-model="valuetime"
                        size="mini"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="timestamp"
                        :default-time="['00:00:00', '23:59:59']"
                        clearable
                        @change="time"
                    />
                </div>
            </div>
            <div class="call-chart">
                <div class="call-totalde-style">
                    <div class="totalde-item">
                        <div>接通率</div>
                        <div>{{ null?0:chartAllData.answerRate }}</div>
                    </div>
                    <div class="totalde-item">
                        <div>总呼叫次数</div>
                        <div>{{ chartAllData.callCount }}</div>
                    </div>
                    <div class="totalde-item">
                        <div>接通次数</div>
                        <div>{{ chartAllData.answerCount }}</div>
                    </div>
                    <div class="totalde-item">
                        <div>累计通话时长</div>
                        <div>{{ chartAllData.durationTotal }}分钟</div>
                    </div>
                    <div class="totalde-item">
                        <div>平均通话时长</div>
                        <div>{{ chartAllData.durationAverage }}秒</div>
                    </div>
                </div>

                <div class="call-total">
                    <span>通话次数统计</span>
                    <div>
                        <div
                            id="first-chart"
                            :style="{width: '100%',height: '100%',minHeight: '400px'}"
                        />
                    </div>
                    <!-- <div v-show="testdrawChartItemName.length">
						<div id="chart-test" :style="{width: '100%',height: '100%',minHeight: '400px',}"
							ref="ChartTest"></div>
                    </div>-->
                    <!-- <div v-show="!firstdrawChartItemName.length">
						<div class="call-analysis-null">
							<span>暂无数据</span>
						</div>
                    </div>-->
                </div>
                <div style="margin-top: 80px;">
                    <span>通话时长统计</span>
                    <div>
                        <div
                            id="second-chart"
                            :style="{width: '100%',height: '100%',minHeight: '400px'}"
                        />
                    </div>
                </div>
                <div style="margin-top: 80px;padding-bottom: 50px;">
                    <span>平均通话时长统计</span>
                    <div
                        id="third-chart"
                        :style="{width: '100%',height: '100%',minHeight: '400px'}"
                    />
                </div>
            </div>
            <div style="height: 50px;" />
        </div>
    </div>
</template>

<script>
import { doptions, list } from '../../../assets/js/falseData.js';
import { callChart } from '../../../api/callPhone_Infor.js';
import { departments, staff } from '../../../api/public_content.js';
export default {
    data() {
        return {
            cascaderKey: 0,
            chartBoolean1: false,
            timeTabs: 0, //时间段
            valuetime: [
                new Date(new Date().toLocaleDateString()).getTime(),
                new Date().valueOf(),
            ], //时间段搜索
            doptions,
            list,
            valued: '',
            ccc: '',
            yg: '',
            lx: '',
            chartAllData: {},
            getDepartments: [],
            getDepartmentId: '',
            getStaff: [],
            callChartsData: {
                keyword: '',
                departmentId: '',
                staffId: '',
                type: '',
                duration: '',
                orderBy: '',
                startTime: '',
                endTime: '',
            },
            statusTime: {
                startTime: '',
                endTime: '',
            },
            testdrawChartItemName: [],
            testdrawChartItemAnswerCount: [],
            firstdrawChartItemName: [],
            firstdrawChartItemAnswerCount: [],
            firstdrawChartItemCallCount: [],
            secondDrawChartItemName: [],
            secondDurationCountCharts: [],
            thirdDrawChartItemName: [],
            third_DrawChartItemDuration_Average: [],
            callType: [
                {
                    name: '全部',
                    id: 0,
                },
                {
                    name: '呼入',
                    id: 1,
                },
                {
                    name: '呼出',
                    id: 2,
                },
            ],
            getCallDuration: [
                {
                    name: '全部',
                    id: 0,
                },
                {
                    name: '1到30秒',
                    id: 1,
                },
                {
                    name: '31到60秒',
                    id: 2,
                },
                {
                    name: '1分钟到3分钟',
                    id: 3,
                },
                {
                    name: '3分钟以上',
                    id: 4,
                },
            ],
            // getOrderBy:[{
            // 		name: '默认排序',
            // 		id: 0
            // 	},{
            // 		name: '接通次数排序',
            // 		id: 1
            // 	},{
            // 		name: '通话时长排序',
            // 		id: 2
            // 	},{
            // 		name: '平均呼叫时长排',
            // 		id: 3
            // 	},{
            // 		name: '接通率排序',
            // 		id: 4
            // 	},]
            testArrayone: [],
            testArraytwo: [],
        };
    },
    beforeDestroy() {
        //  销毁 echarts 实例
        let myChart = this.$echarts.getInstanceByDom(
            document.getElementById('second-chart')
        );
        let myChart1 = this.$echarts.getInstanceByDom(
            document.getElementById('third-chart')
        );
        let myChart2 = this.$echarts.getInstanceByDom(
            document.getElementById('chart-test')
        );
        if (myChart) {
            this.$echarts.dispose(myChart);
        }
        if (myChart1) {
            this.$echarts.dispose(myChart1);
        }
        if (myChart2) {
            this.$echarts.dispose(myChart2);
        }
    },
    methods: {
        time(val) {
            let startTim;
            let endTim;
            if (!val) {
                startTim = '';
                endTim = '';
            } else {
                startTim = val[0] / 1000;
                endTim = val[1] / 1000;
            }
            // if (val == null) {
            // 	// this.timeTabs = 0;
            // 	this.valuetime = "";
            // 	this.getCallChart();
            // } else {
            // 	// this.timeTabs = "";
            // 	this.valuetime = val;
            // 	this.getCallChart();
            // }
            // let startTim = val[0] / 1000
            // let endTim = val[1] / 1000
            // console.log(this.valuetime[0]/ 1000,this.valuetime[1]/ 1000, 'dla')
            // this.callChartsData.startTime = val[0] / 1000;
            // this.callChartsData.endTime = val[1] / 1000;
            this.timeChange(startTim, endTim);
        },
        timeChange(startTim, endTim) {
            this.callChartsData.startTime = startTim;
            this.callChartsData.endTime = endTim;
            console.log(startTim, endTim);
            // console.log(this.callChartsData.startTime ,'charst')
            this.getCallChart();
        },

        drawChart1() {
            let that = this;
            let myChart = '';
            // this.$nextTick(()=>{
            //给图表添加点击事件，改变第一个图表的内容
            // if(this.firstdrawChartItemName.length){
            myChart = this.$echarts.init(
                document.getElementById('first-chart')
            );
            // }

            // let myChart = this.$echarts.init(this.$refs.firstChart);
            // console.log(that.chartAllData.callCountCharts,'a')
            //图表的配置
            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                // color: ['#4086ec'],
                xAxis: [
                    {
                        type: 'category',
                        data: this.firstdrawChartItemName,
                        axisTick: {
                            interval: 0,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                    },
                ],
                legend: {
                    x: '5%',
                    data: ['呼叫次数', '接通次数'],
                },
                series: [
                    {
                        name: '呼叫次数',
                        type: 'bar',
                        barWidth: '20%',
                        barGap: 0,
                        data: this.firstdrawChartItemCallCount,
                        itemStyle: {
                            normal: {
                                color: '#4086ec',
                            },
                        },
                    },
                    {
                        name: '接通次数',
                        type: 'bar',
                        barWidth: '20%',
                        barGap: 0,
                        data: this.firstdrawChartItemAnswerCount,
                        itemStyle: {
                            normal: {
                                color: '#79a7ed',
                            },
                        },
                    },
                ],
            });
        },
        drawChart2() {
            let that = this;
            //给图表添加点击事件，改变第二个图表的内容
            let myChart = this.$echarts.getInstanceByDom(
                document.getElementById('second-chart')
            );
            if (myChart) {
                this.$echarts.dispose(myChart);
            }
            myChart = this.$echarts.init(
                document.getElementById('second-chart')
            );
            myChart.setOption({
                xAxis: [
                    {
                        type: 'category',
                        data: this.secondDrawChartItemName,
                        axisTick: {
                            interval: 0,
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '单位（分钟）',
                    },
                ],
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                series: [
                    {
                        name: '通话时长统计',
                        type: 'bar',
                        barWidth: '20%',
                        barGap: 0,
                        data: this.secondDurationCountCharts,
                        itemStyle: {
                            normal: {
                                color: '#4086ec',
                            },
                        },
                    },
                ],
            });
        },
        drawChart3() {
            let that = this;
            //给图表添加点击事件，改变第二个图表的内容
            let myChart = this.$echarts.getInstanceByDom(
                document.getElementById('third-chart')
            );
            if (myChart) {
                this.$echarts.dispose(myChart);
            }
            myChart = this.$echarts.init(
                document.getElementById('third-chart')
            );
            myChart.setOption({
                xAxis: [
                    {
                        type: 'category',
                        data: this.thirdDrawChartItemName,
                        axisTick: {
                            interval: 0,
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '单位（秒）',
                    },
                ],
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                series: [
                    {
                        name: '平均通话时长统计',
                        type: 'bar',
                        barWidth: '20%',
                        barGap: 0,
                        data: this.third_DrawChartItemDuration_Average,
                        itemStyle: {
                            normal: {
                                color: '#4086ec',
                            },
                        },
                    },
                ],
            });
        },

        // 测试图表
        drawChartTest() {
            let that = this;
            //给图表添加点击事件，改变第二个图表的内容
            let myChart = this.$echarts.getInstanceByDom(
                document.getElementById('chart-test')
            );
            if (myChart) {
                this.$echarts.dispose(myChart);
            }
            myChart = this.$echarts.init(document.getElementById('chart-test'));
            myChart.setOption({
                xAxis: [
                    {
                        type: 'category',
                        data: this.testdrawChartItemName,
                        axisTick: {
                            interval: 0,
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                    },
                ],
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                series: [
                    {
                        name: '平均通话时长统计',
                        type: 'bar',
                        barWidth: '20%',
                        barGap: 0,
                        data: this.testdrawChartItemAnswerCount,
                        itemStyle: {
                            normal: {
                                color: '#4086ec',
                            },
                        },
                    },
                ],
            });
        },

        getCallChart() {
            callChart(this.callChartsData).then((res) => {
                console.log(res.data, 'chart');
                this.chartAllData = res.data;
                this.firstdrawChartItemName = [];
                this.firstdrawChartItemCallCount = [];
                this.firstdrawChartItemAnswerCount = [];
                this.secondDrawChartItemName = [];
                this.secondDurationCountCharts = [];
                this.thirdDrawChartItemName = [];
                this.third_DrawChartItemDuration_Average = [];
                for (
                    let i = 0;
                    i < this.chartAllData.callCountCharts.length;
                    i++
                ) {
                    this.firstdrawChartItemName.push(
                        this.chartAllData.callCountCharts[i].name
                    );
                    this.firstdrawChartItemCallCount.push(
                        this.chartAllData.callCountCharts[i].callCount
                    );
                    this.firstdrawChartItemAnswerCount.push(
                        this.chartAllData.callCountCharts[i].answerCount
                    );
                }
                for (
                    let d = 0;
                    d < this.chartAllData.durationCountCharts.length;
                    d++
                ) {
                    this.secondDrawChartItemName.push(
                        this.chartAllData.durationCountCharts[d].name
                    );
                    this.secondDurationCountCharts.push(
                        this.chartAllData.durationCountCharts[d].durationTotal
                    );
                }
                for (
                    let i = 0;
                    i < this.chartAllData.averageCountCharts.length;
                    i++
                ) {
                    this.thirdDrawChartItemName.push(
                        this.chartAllData.averageCountCharts[i].name
                    );
                    this.third_DrawChartItemDuration_Average.push(
                        this.chartAllData.averageCountCharts[i].durationAverage
                    );
                }
                this.drawChart1();
                this.drawChart2();
                this.drawChart3();
            });
        },
        getStaff_Val(val) {
            ++this.cascaderKey;
            this.yg = '';
            this.lx = '';
            this.valued = '';
            this.valuetime = '';
            this.callChartsData.staffId = '';
            this.callChartsData.type = '';
            this.callChartsData.duration = '';
            this.callChartsData.startTime = '';
            this.callChartsData.endTime = '';
            let valsss = val[val.length - 1];
            this.getDepartment(valsss);
        },
        getDepartment(val) {
            // this.$refs.cascaderHandled.dropDownVisible = false;
            departments({}).then((res) => {
                this.getDepartments = res.data.list;
                // console.log(res, 'department')
            });
            this.callChartsData.departmentId = val;
            console.log(val, 'departmentId');
            staff({
                departmentId: val,
            }).then((res) => {
                this.getStaff = res.data.list;
                // console.log(res, 'staff')
            });
            this.loading = true;
            this.getCallChart();
        },
        getDepartmentMemberChange(val) {
            // console.log(val, 'vals')
            this.lx = '';
            this.valued = '';
            this.valuetime = '';
            this.callChartsData.type = '';
            this.callChartsData.duration = '';
            this.callChartsData.startTime = '';
            this.callChartsData.endTime = '';
            this.callChartsData.staffId = val;
            this.getCallChart();
        },
        getCallTypeChange(val) {
            // console.log(val, 'getCallTypeChange')
            this.valued = '';
            this.valuetime = '';
            this.callChartsData.duration = '';
            this.callChartsData.startTime = '';
            this.callChartsData.endTime = '';
            this.callChartsData.type = val;
            this.getCallChart();
        },
        callDurationChange(callDurationType) {
            this.valuetime = '';
            this.callChartsData.startTime = '';
            this.callChartsData.endTime = '';
            this.callChartsData.duration = callDurationType;
            this.getCallChart();
            // console.log(valType,'callTypeChange')
        },
    },
    created() {
        this.callChartsData.startTime = parseInt(
            new Date(new Date().toLocaleDateString()).getTime() / 1000
        );
        this.callChartsData.endTime = parseInt(new Date().valueOf() / 1000);
        // this.getCallChart() //导致可视化数据渲染两次
        this.getDepartment();
        // this.drawChartTest()
    },
};
</script>

<style lang="less" scoped="scoped">
.content-inAnalysis {
    display: flex;
    flex-direction: column;

    .content-all {
        margin: 0 15px 0 15px;
        height: 100%;

        .analysis-select {
            // border: 1px solid black;
            background-color: #ffffff;
            padding: 10px 0 10px 0;
            margin-top: 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: row;

            .call-item,
            .select-date {
                display: flex;
                flex-direction: row;
            }

            .span-ts {
                display: block;
                margin-top: 1px;
                font-size: 14px;
                line-height: 27px;
            }

            .select-width {
                width: 200px;
                height: 27px;
            }
        }

        .call-chart {
            margin-top: 20px;
            // border: 1px solid black;
            border-radius: 10px;
            text-align: center;
            width: 100%;

            background-color: rgba(255, 255, 255, 1);

            .call-total {
                margin-top: 50px;
            }

            .call-totalde-style {
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                .totalde-item {
                    margin-top: 30px;
                    width: 180px;
                    height: 60px;
                    border-radius: 4px;
                    color: #ffffff;
                    font-size: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(64, 134, 236, 1);
                }
            }
        }

        .mar-left {
            margin-left: 10px;
        }
    }

    .call-analysis-null {
        text-align: center;
        // border: 1px solid black;
        height: 300px;
        line-height: 300px;
    }
}
</style>
