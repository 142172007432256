let doptions = [
    {
        value: '选项1',
        label: '黄金糕',
    },
    {
        value: '选项2',
        label: '双皮奶',
    },
    {
        value: '选项3',
        label: '蚵仔煎',
    },
    {
        value: '选项4',
        label: '龙须面',
    },
    {
        value: '选项5',
        label: '北京烤鸭',
    },
];

let list = [
    {
        id: 1,
        callerName: '王小虎',
        staff: '王小虎',
        type: '呼出',
        callDate: '',
        calledName: 'wwwx',
        calledNumber: '18269559803',
        followedConent: '不需要',
        recordUrl: '',
        duration: '30秒',
        createdTime: '3000',
        recordUrl: '',
        staff: '王小虎',
        callCount: '100',
        answerCount: '90',
        greater30sCount: 35,
        greater60sCount: 10,
        durationTotal: '40分钟',
        durationAverage: '30秒',
        answerRate: '80%',
    },
    {
        id: 2,
        callerName: '王小虎',
        type: '呼出',
        staff: '王小虎',
        callDate: '',
        calledName: 'wwwx',
        calledNumber: '18269559803',
        followedConent: '不需要',
        recordUrl: '',
        duration: '30秒',
        createdTime: '6000',
        recordUrl: '',
        staff: '王小虎',
        callCount: '100',
        answerCount: '90',
        greater30sCount: 35,
        greater60sCount: 10,
        durationTotal: '40分钟',
        durationAverage: '30秒',
        answerRate: '80%',
    },
    {
        id: 3,
        callerName: '王小虎',
        type: '呼出',
        staff: '王小虎',
        callDate: '',
        calledName: 'wwwx',
        calledNumber: '18269559803',
        followedConent: '不需要',
        recordUrl: '',
        duration: '30秒',
        createdTime: '12000',
        recordUrl: '',
        staff: '王小虎',
        callCount: '100',
        answerCount: '90',
        greater30sCount: 35,
        greater60sCount: 10,
        durationTotal: '40分钟',
        durationAverage: '30秒',
        answerRate: '80%',
    },
    {
        id: 4,
        callerName: '王小虎',
        staff: '王小虎',
        callCount: '100',
        answerCount: '90',
        greater30sCount: 35,
        greater60sCount: 10,
        durationTotal: '40分钟',
        durationAverage: '30秒',
        answerRate: '80%',
        type: '呼出',
        callDate: '',
        calledName: 'wwwx',
        calledNumber: '18269559803',
        followedConent: '不需要',
        recordUrl: '',
        duration: '30秒',
        createdTime: '35000',
        recordUrl: '',
        answerRate: '80%',
    },
];

// 单人线索假数据
let personClueData = [
    {
        createdTime: '2020.08.15 10:23:15',
        caller: '小明',
        called: '小红',
        audioUrl: 'https://vod.ruotongmusic.com/sv/14d5242d-179cbef459a/14d5242d-179cbef459a.wav',
    },
    {
        createdTime: '2020.08.15 10:23:15',
        caller: '小明',
        called: '小红',
        audioUrl: 'https://vod.ruotongmusic.com/sv/14d5242d-179cbef459a/14d5242d-179cbef459a.wav',
    },
    {
        createdTime: '2020.08.15 10:23:15',
        caller: '小明',
        called: '小红',
        audioUrl: 'https://vod.ruotongmusic.com/sv/14d5242d-179cbef459a/14d5242d-179cbef459a.wav',
    },
    {
        createdTime: '2020.08.15 10:23:15',
        caller: '小明',
        called: '小红',
        audioUrl: 'https://vod.ruotongmusic.com/sv/14d5242d-179cbef459a/14d5242d-179cbef459a.wav',
    },
    {
        createdTime: '2020.08.15 10:23:15',
        caller: '小明',
        called: '小红',
        audioUrl: 'https://vod.ruotongmusic.com/sv/14d5242d-179cbef459a/14d5242d-179cbef459a.wav',
    },
    {
        createdTime: '2020.08.15 10:23:15',
        caller: '小明',
        called: '小红',
        audioUrl: 'https://vod.ruotongmusic.com/sv/14d5242d-179cbef459a/14d5242d-179cbef459a.wav',
    },
];

export { doptions, list, personClueData };
